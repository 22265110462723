<template>
  <div class="general-public">
    <div class="catch-copy-container">
      <div
        class="catch-copy-sub container is-fluid border-text"
      >
        <div class="catch-copy-sub-title">
          快適な暮らしのために
          <!-- <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 400">
            <text x="0" y="50%">快適な暮らしのために</text>
          </svg> -->
        </div>
        <div
          class="catch-copy-sub-subtitle"
        >
          豊富な専門知識と確かな技術を備えた「if」のスタッフが、お客様のご要望に丁寧にお応えいたします。
        </div>
      </div>
    </div>
    <div class="container is-fluid house-content-container">
      <div class="columns house-content-container-wrapper">
        <div
          class="column is-two-fifths house-content-container-title"
        >
          <div class="house-content-container-title-text">
            現在のお住まいに困りごとはありませんか？
          </div>
        </div>
        <div class="column is-three-fifths house-content-container-details">
          <div style="padding: 2.5rem 1.5rem 2.5rem">
            <div
              style="
                font-size: 1.175rem;
                font-weight: 700;
                padding-bottom: 10px;
              "
            >
              古くなって使い勝手が悪い
            </div>
            <div>
              お子さまの独立、ご自身のリタイアや身体の衰え、二世帯同居...
            </div>
            <div>
              お客様のご要望やライフステージに合ったリフォームを承ります。
            </div>
            <!-- <div style="padding-top:10px;">
              <span class="tag is-info" style="margin-right:2.25px;">リーズナブルなリフォーム</span>
              <span class="tag is-info" style="margin-right:2.25px;">安心・安全のためのリフォーム</span>
              <span class="tag is-info" style="margin-right:2.25px;">お洒落なリフォーム</span>
              <span class="tag is-info" style="margin-right:2.25px;">住宅設備の更新</span>
            </div> -->
          </div>
          <div style="padding: 2.5rem 1.5rem 2.5rem">
            <div
              style="
                font-size: 1.175rem;
                font-weight: 700;
                padding-bottom: 10px;
              "
            >
              使用頻度の高い部分が老朽化してきた
            </div>
            <div>
              キッチン、トイレ、バスなどの水回りをはじめ、日常的に使用する部分は老朽化も早い箇所です。
            </div>
            <div>
              部分的な修理から全体のリフォームまで、ご要望に応じて承ります。
            </div>
          </div>
          <div style="padding: 2.5rem 1.5rem 5rem">
            <div
              style="
                font-size: 1.175rem;
                font-weight: 700;
                padding-bottom: 10px;
              "
            >
              災害に遭って修理しなければならない
            </div>
            <div>
              地震をはじめ水害・風害・雪害といった災害によって住まいに被害が出てしまった場合の対応として、保険工事の段取りから工事完了まで請負います。
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="catch-copy-container-second">
      <div class="catch-copy-container-second-content">
        <div class="catch-copy-container-second-content-title">
          その他にも、こんな困りごとはありませんか？
        </div>
        <!-- <div style="font-size: 1.75rem; font-weight: 700;">お客様のご要望に丁寧にお応えいたします。</div> -->
        <div
         class="catch-copy-container-second-content-details"
        >
          <div class="catch-copy-container-second-content-details-item" v-for="(item, index) in itemList" :key="index">□{{item}}</div>
        </div>
      </div>
    </div>
    <div class="house-solution-content">
      <div class="container is-fluid house-solution-content-container">
        <div
          class="house-solution-content-container-text"
        >
          {{"豊富な専門知識と確かな技術を備えた「if」のスタッフが、お客様のご要望に丁寧にお応えいたします。\n一連の作業は全て自社施行であり、スムーズな対応をお約束します。"}}
        </div>
        <div class="columns is-multiline">
          <div class="column is-one-quarter">
            <div class="card" style="height: 100%">
              <div class="card-image">
                <figure class="image is-3by2">
                  <img
                    src="./../../../assets/YqcK9t.png"
                    alt="Placeholder image"
                  />
                </figure>
              </div>
              <div class="card-content">
                <div class="content">
                  <div style="font-size: 1.25rem; font-weight: 700">
                    ハウスクリーニング
                  </div>
                  <div style="font-size: 1rem; font-weight: 400">
                    <div>
                      全体クリーニングから部分クリーニングまで(トイレ、浴室、窓、換気扇、エアコン等)施工いたします。
                    </div>
                    <div>ワンルームから戸建てまで、柔軟に対応いたします。</div>
                  </div>
                  <div style="font-size: 0.75rem; font-weight: 500"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-one-quarter">
            <div class="card" style="height: 100%">
              <div class="card-image">
                <figure class="image is-3by2">
                  <img
                    src="./../../../assets/hokenkouji.png"
                    alt="Placeholder image"
                  />
                </figure>
              </div>
              <div class="card-content">
                <div class="content">
                  <div style="font-size: 1.25rem; font-weight: 700">
                    リフォーム工事
                  </div>
                  <div style="font-size: 1rem; font-weight: 400">
                    お客様の要望に合わせてのリフォームから当社にお任せのプランまで当社が責任持って施行いたします。
                  </div>
                  <div style="font-size: 0.75rem; font-weight: 500"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-one-quarter">
            <div class="card" style="height: 100%">
              <div class="card-image">
                <figure class="image is-3by2">
                  <img
                    src="./../../../assets/gaikoukouji.png"
                    alt="Placeholder image"
                  />
                </figure>
              </div>
              <div class="card-content">
                <div class="content">
                  <div style="font-size: 1.25rem; font-weight: 700">
                    外構工事
                  </div>
                  <div style="font-size: 1rem; font-weight: 400">
                    屋根の吹き替え・塗装、外壁のサイディング張り替え工事・塗装、駐車場・駐輪場の整備等、施工いたします。
                  </div>
                  <div style="font-size: 0.75rem; font-weight: 500"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-one-quarter">
            <div class="card" style="height: 100%">
              <div class="card-image">
                <figure class="image is-3by2">
                  <img
                    src="./../../../assets/tetsubi-kouji.png"
                    alt="Placeholder image"
                  />
                </figure>
              </div>
              <div class="card-content">
                <div class="content">
                  <div style="font-size: 1.25rem; font-weight: 700">
                    設備工事
                  </div>
                  <div style="font-size: 1rem; font-weight: 400">
                    給排水工事全般承ります。
                  </div>
                  <div style="font-size: 0.75rem; font-weight: 500"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-one-quarter">
            <div class="card" style="height: 100%">
              <div class="card-image">
                <figure class="image is-3by2">
                  <img
                    src="./../../../assets/zoen.png"
                    alt="Placeholder image"
                  />
                </figure>
              </div>
              <div class="card-content">
                <div class="content">
                  <div style="font-size: 1.25rem; font-weight: 700">
                    造園工事
                  </div>
                  <div style="font-size: 1rem; font-weight: 400">
                    草取り、庭木の剪定、抜根等お庭のこと全般承ります。
                  </div>
                  <div style="font-size: 0.75rem; font-weight: 500"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-one-quarter">
            <div class="card" style="height: 100%">
              <div class="card-image">
                <figure class="image is-3by2">
                  <img
                    src="./../../../assets/1050041_m.jpg"
                    alt="Placeholder image"
                  />
                </figure>
              </div>
              <div class="card-content">
                <div class="content">
                  <div style="font-size: 1.25rem; font-weight: 700">
                    部屋の片付け
                  </div>
                  <div style="font-size: 1rem; font-weight: 400">
                    部屋の片付け・不用品処分、遺品整理等施工いたします。
                  </div>
                  <div style="font-size: 0.75rem; font-weight: 500"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-one-quarter">
            <div class="card" style="height: 100%">
              <div class="card-image">
                <figure class="image is-3by2">
                  <img
                    src="./../../../assets/kaitaikouji.png"
                    alt="Placeholder image"
                  />
                </figure>
              </div>
              <div class="card-content">
                <div class="content">
                  <div style="font-size: 1.25rem; font-weight: 700">
                    解体工事
                  </div>
                  <div style="font-size: 1rem; font-weight: 400">
                    部分解体・内装解体、平屋からビルの解体工事まで施工いたします。
                  </div>
                  <div style="font-size: 0.75rem; font-weight: 500"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ToContactContent />
    <!-- <div class="container is-fluid">
      <div class="columns">
        <div class="column is-half">
          <div style="padding:3rem 1.5rem 3rem;">
            <div>最初に家を見つけて、家をすばやく見学しましょう</div>
            <div>Redfin Agentでツアーを即座に予約することで、他の購入者に先んじることができるように、5分ごとにリストを更新します</div>
          </div>
          <div style="padding:3rem 1.5rem 3rem;">
            <div>最初に家を見つけて、家をすばやく見学しましょう</div>
            <div>Redfin Agentでツアーを即座に予約することで、他の購入者に先んじることができるように、5分ごとにリストを更新します</div>
          </div>
          <div style="padding:3rem 1.5rem 3rem;">
            <div>最初に家を見つけて、家をすばやく見学しましょう</div>
            <div>Redfin Agentでツアーを即座に予約することで、他の購入者に先んじることができるように、5分ごとにリストを更新します</div>
          </div>
        </div>
        <div class="column is-half" style="align-items:center;display:flex;">
          <div style="font-size:2rem;font-weight:700;padding:10px;">なぜ私たちと一緒に購入するのですか？</div>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component";
import { namespace } from "vuex-class";
import ToContactContent from '@/components/ToContactContent.vue';

const SystemModule = namespace("system");

@Options({
  components: {
    ToContactContent
  },
})
export default class House extends Vue {
  public async mounted(): Promise<void> {
    window.scrollTo(0, 0);
    await this.updatePageLoadingState(false);
  }

  @SystemModule.Action("updatePageLoadingState")
  private updatePageLoadingState!: (state: boolean) => Promise<void>;

  private async routerView(to: string) {
    await this.updatePageLoadingState(true);
    this.$router.push(to);
  }

  private itemList = [
    "壁紙を貼り替えて模様替えしたい ",
    "畳をフローリングに替えたい ",
    "キッチンを取り替えたい ",
    "網戸を張り替えて欲しい ",
    "ドアの開閉をスムーズに直してほしい ",
    "照明を取り替えたい ",
    "遺品整理を任せたい ",
    "水漏れしてしまったので急いで直してほしい ",
    "窓ガラスを割ってしまったので直してほしい ",
    "剥がれてしまったタイルを直してほしい ",
    "家中のエアコンをクリーニングしてほしい ",
    "外壁を塗装し直してキレイにしたい ",
    "駐車スペースを拡げて整備したい ",
    "庭にシンボルツリーと花壇がほしい ",
    "所有しているアパートの管理をお願いしたい ",
    "アパートの入居率をアップしたい ",
    "入居者の退去立会いを任せたい ",
    "実家が空き家になってしまいどうしたらいいか分からない ",
    "部屋をおしゃれにしたい ",
    "バリアフリーな家にしたい ",
    "廊下やお風呂に手すりをつけたい ",
    "電球交換など高所の作業を頼みたい ",
    "お風呂をユニットバスに変えたい ",
    "ウィルス対策になるリフォームをしたい ",
    "手洗い周辺の壁紙を交換したい ",
    "畳をフローリングに変えたい ",
    "草取りと剪定をお願いしたい ",
    "換気扇をキレイにしたい ",
    "障子を張り替えたい ",
    "その他"
  ]
}
</script>
<style lang="scss" scoped>
@import "@/assets/util.scss";

.house-solution-content {
  background-color: #fafafa;

  .house-solution-content-container {
    padding: 2.5rem 1.5rem 5rem;

    .house-solution-content-container-text {
      padding: 10px;
      padding: 1rem 0rem 2.5rem;
      text-align: center;
      font-size: 1.5rem;
      font-weight: 700;
      text-align: left;
      white-space: pre-wrap;
      word-wrap:break-word;
    }

    @include sp {
      .house-solution-content-container-text {
        padding: 10px;
        padding: 1rem 0rem 2.5rem;
        text-align: center;
        font-size: 4.5vw;
        font-weight: 700;
        text-align: left;
        white-space: pre-wrap;
        word-wrap:break-word;
      }
    }
  }
}

.house-content-container {
  .house-content-container-wrapper {
    .house-content-container-title {
      align-items: center;
      display: flex;

      .house-content-container-title-text {
        font-size: 1.7rem;
        font-weight: 700;
        padding: 10px;
      }
    }

    .house-content-container-details {

    }

    @include sp {
      .house-content-container-title {
        padding: 10px;

        .house-content-container-title-text {
          font-size: 1.7rem;
          font-weight: 700;
          padding-top: 3rem;
          margin: auto;
        }
      }
    }
  }
}

svg {
  width: 100%;
  height: auto;
  overflow: visible;
}
text {
  fill: #2e3192;
  stroke: #adacb9;
  stroke-width: 0.5px;
  stroke-linejoin: round;
}

.general-public {
  padding-top: 3.25rem;

  .catch-copy-container {
    height: 50vh;
    background: linear-gradient(
          130deg,
          rgba(231, 223, 223, 0.6),
          rgb(190 207 206 / 99%)
        )
        fixed,
      url("./../../../assets/kitchen_1m.jpeg");
    // background: url("./../assets/YqcK9t.png");
    background-size: cover;
    background-position: center;
    text-align: center;

    .catch-copy-sub {
      transform: translateY(30%);
      width: 100vw;
      height: 100%;

      .catch-copy-sub-title {
        font-size: 1.7rem;
        font-weight: 700;
        padding: 10px;
      }

      .catch-copy-sub-subtitle {
        font-size: 1rem;
        font-weight: 500;
        padding: 10px;
        text-align: center;
      }
    }
  }

  .catch-copy-container-second {
    background: linear-gradient(
          130deg,
          rgba(54, 42, 42, 0.6),
          rgba(29, 29, 24, 0.5)
        )
        fixed,
      url("./../../../assets/YqcK9t.png");
    background-size: cover;
    background-position: center;
    text-align: center;

    .catch-copy-container-second-content {
      color: white;
      padding: 2.5rem 1.5rem 2.5rem;

      .catch-copy-container-second-content-title {
        font-size: 1.6rem;
        font-weight: 700;
        padding-bottom: 20px;
      }

      .catch-copy-container-second-content-details {
        font-size: 1rem;
        font-weight: 500;
        text-align: left;

        &-item {
          width: 30vw;
          padding: 4px;
          margin-left: auto;
          margin-right: auto;
        }
      }

      @include tablet {
        .catch-copy-container-second-content-details {
          font-size: 1rem;
          font-weight: 500;
          text-align: left;

          &-item {
            width: 50vw;
            padding-top: 4px;
          }
        }
      }

      @include sp {
        .catch-copy-container-second-content-details {
          font-size: 1rem;
          font-weight: 500;
          text-align: left;

          &-item {
            width: 85vw;
            padding-top: 4px;
          }
        }
      }
    }
  }
}
</style>
