
import { Options, Vue } from "vue-class-component";
import { namespace } from "vuex-class";
import ToContactContent from '@/components/ToContactContent.vue';

const SystemModule = namespace("system");

@Options({
  components: {
    ToContactContent
  },
})
export default class House extends Vue {
  public async mounted(): Promise<void> {
    window.scrollTo(0, 0);
    await this.updatePageLoadingState(false);
  }

  @SystemModule.Action("updatePageLoadingState")
  private updatePageLoadingState!: (state: boolean) => Promise<void>;

  private async routerView(to: string) {
    await this.updatePageLoadingState(true);
    this.$router.push(to);
  }

  private itemList = [
    "壁紙を貼り替えて模様替えしたい ",
    "畳をフローリングに替えたい ",
    "キッチンを取り替えたい ",
    "網戸を張り替えて欲しい ",
    "ドアの開閉をスムーズに直してほしい ",
    "照明を取り替えたい ",
    "遺品整理を任せたい ",
    "水漏れしてしまったので急いで直してほしい ",
    "窓ガラスを割ってしまったので直してほしい ",
    "剥がれてしまったタイルを直してほしい ",
    "家中のエアコンをクリーニングしてほしい ",
    "外壁を塗装し直してキレイにしたい ",
    "駐車スペースを拡げて整備したい ",
    "庭にシンボルツリーと花壇がほしい ",
    "所有しているアパートの管理をお願いしたい ",
    "アパートの入居率をアップしたい ",
    "入居者の退去立会いを任せたい ",
    "実家が空き家になってしまいどうしたらいいか分からない ",
    "部屋をおしゃれにしたい ",
    "バリアフリーな家にしたい ",
    "廊下やお風呂に手すりをつけたい ",
    "電球交換など高所の作業を頼みたい ",
    "お風呂をユニットバスに変えたい ",
    "ウィルス対策になるリフォームをしたい ",
    "手洗い周辺の壁紙を交換したい ",
    "畳をフローリングに変えたい ",
    "草取りと剪定をお願いしたい ",
    "換気扇をキレイにしたい ",
    "障子を張り替えたい ",
    "その他"
  ]
}
